import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import { uiActions } from "../store/ui-slice";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import CompressiblePanel from "../layout/CompressiblePanel";
import PlayerIndexDrilldownPanel from "../components/player-side-panels/PlayerIndexDrilldownPanel";
import { EMPTY_VIEW_CONTEXT } from "../hooks/use-cross-page-view-context";
import NavigationBar, {
    TeamSelector,
    NavigationSetting,
} from "../controls/NavigationBar";
import TeamPlayerIndexLegend from "../components/legends/TeamPlayerIndexLegend";
import PlayerTradStatsTileLegend from "../components/legends/PlayerTradStatsTileLegend";
import ScoutingTileLegend from "../components/legends/ScoutingTileLegend";
import PlayerPerformanceTileLegend from "../components/legends/PlayerPerformanceTileLegend";
import PlayerCapTileLegend from "../components/legends/PlayerCapTileLegend";
import CapReportView from "../components/CapReportView";
import DepthChartView from "../components/DepthChartView";
import TeamPlayerImpactView from "../components/TeamPlayerImpactView";
import RosterEditView from "../components/RosterEditView";
import TeamPlayerSeasonPerformanceView from "../components/TeamPlayerSeasonPerformanceView";
import { OptionRadioBar } from "../controls/OptionRadioBar";
import Toggle from "../controls/buttons/Toggle";

import useAuth from "../hooks/use-auth";

import classes from "./TeamRosterPages.module.css";

const pageClassOptions = [
    {
        id: "depthchart",
        full_label: "Depth Chart",
        short_label: "Depth Chart",
        toggle_child: "rosteredit",
    },
    {
        id: "playerseasonperformance",
        full_label: "Player Performance",
        short_label: "Performance",
    },
    {
        id: "playerimpact",
        full_label: "Player Impact",
        short_label: "Impact",
    },
    {
        id: "capreport",
        full_label: "Cap Analysis",
        short_label: "Cap Analysis",
    },
    {
        id: "rosteredit",
        full_label: "Fuck Around",
        short_label: "Fuck Around",
    },
];

const childrenPageClassOptions = pageClassOptions.reduce((acc, option) => {
    if (option.toggle_child) {
        acc[option.toggle_child] = option.id;
    }
    return acc;
}, {});

const RosterViewSelector = ({
    options,
    selectedPageClass,
    onPageClassSelection,
}) => {
    const barSelection = options.find(
        (option) =>
            option.id === selectedPageClass ||
            option.toggle_child === selectedPageClass
    );

    const currentToggleChild = options.find(
        (option) => barSelection.toggle_child === option.id
    );

    const [childToggled, setChildToggled] = useState(
        currentToggleChild && selectedPageClass === currentToggleChild.id
    );

    useEffect(() => {
        if (currentToggleChild && selectedPageClass === currentToggleChild.id) {
            setChildToggled(true);
        } else {
            setChildToggled(false);
        }
    }, [selectedPageClass, currentToggleChild]);

    const handlePageClassSelect = (option) => {
        if (
            option.id === selectedPageClass ||
            // Prevent clicking on the parent from changing the selection
            // if the child is selected
            option.toggle_child === selectedPageClass
        ) {
            return;
        }
        setChildToggled(false);
        onPageClassSelection(option.id);
    };

    const handleChildToggle = () => {
        if (!childToggled) {
            currentToggleChild && onPageClassSelection(currentToggleChild.id);
        } else {
            barSelection && onPageClassSelection(barSelection.id);
        }
        setChildToggled(!childToggled);
    };

    return (
        <NavigationSetting>
            <OptionRadioBar
                options={options.filter(
                    (option) =>
                        !Object.keys(childrenPageClassOptions).includes(
                            option.id
                        )
                )}
                selectedOption={barSelection}
                onSelect={handlePageClassSelect}
                OptionComponent={({ optionValue }) => (
                    <div className={classes.page_class_option}>
                        <div className={classes.full_label}>
                            {optionValue?.full_label}
                        </div>
                        <div className={classes.short_label}>
                            {optionValue?.short_label}
                        </div>
                    </div>
                )}
            />
            {currentToggleChild && (
                <>
                    <div className={classes.toggle_child}>
                        <div className={classes.full_label}>
                            {currentToggleChild?.full_label}
                        </div>
                        <div className={classes.short_label}>
                            {currentToggleChild?.short_label}
                        </div>
                    </div>
                    <Toggle
                        isChecked={childToggled}
                        onToggle={handleChildToggle}
                    />
                </>
            )}
        </NavigationSetting>
    );
};

const defaultViewContext = {
    playerId: null,
    competitionId: null,
    metricContext: {
        stack: null,
        activeMetric: null,
    },
};

const TeamRosterPages = ({
    pageClass,
    teamId,
    metricsSeasonId = null,
    viewContextProp = defaultViewContext,
    onViewContextChange,
    onMenuOpen,
    onSelectionChange,
}) => {
    const dispatch = useDispatch();

    const { checkPermission } = useAuth();

    const canViewContractInformation = checkPermission(
        "core.can_view_contract_information"
    );

    const [pageClassTransitioning, setPageClassTransitioning] = useState(false);
    const [pageViewContentLoading, setPageViewContentLoading] = useState(true);

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );
    const seasonId = metricsSeasonId ? metricsSeasonId : currentSeasonId;

    const [viewContext, setViewContext] = useState(viewContextProp);

    const [drilldownOpen, setDrilldownOpen] = useState(false);

    const [drilldownPlayerPeerData, setDrilldownPlayerPeerData] =
        useState(null);

    const tileViewMode = useSelector((state) => state.ui.playerTileMode);

    const handleSelectTeamId = (teamId) => {
        const updatedPageClass = Object.keys(childrenPageClassOptions).includes(
            pageClass
        )
            ? childrenPageClassOptions[pageClass]
            : pageClass;

        onSelectionChange &&
            onSelectionChange({
                teamId: teamId,
                pageClass: updatedPageClass,
            });
    };

    const handlePageClassSelection = (pageClass) => {
        setPageClassTransitioning(true);
        setPageViewContentLoading(true);

        setTimeout(() => {
            onSelectionChange &&
                onSelectionChange({
                    teamId: teamId,
                    pageClass: pageClass,
                });
            setPageClassTransitioning(false);
        }, 150);
    };

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const depth_chart_config =
        teams_datastashes &&
        teams_datastashes[teamId] &&
        teams_datastashes[teamId].depth_chart_config;

    // Fetch required data
    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, teams, dispatch]);

    useEffect(() => {
        if (teams && team && !teams[team.affiliations[0]]) {
            dispatch(fetchTeam(team.affiliations[0]));
            dispatch(uiActions.showLoader());
        }
    }, [team, teams, dispatch]);

    useEffect(() => {
        if (!depth_chart_config) {
            dispatch(fetchTeamDatastash(teamId, "depth_chart_config"));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, depth_chart_config, dispatch]);

    useEffect(() => {
        if (team && depth_chart_config) {
            dispatch(uiActions.hideLoader());
        }
    }, [team, depth_chart_config, dispatch]);

    useEffect(() => {
        if (!pageViewContentLoading) {
            dispatch(uiActions.hideLoader());
        }
    }, [pageViewContentLoading, dispatch]);

    const [activeMetricContext, setActiveMetricContext] = useState({
        stack:
            viewContextProp &&
            viewContextProp.metricContext &&
            viewContextProp.metricContext.stack,
        activeMetric:
            viewContextProp &&
            viewContextProp.metricContext &&
            viewContextProp.metricContext.activeMetric,
    });

    const handlePlayerClicked = useCallback(
        (playerId, competitionId, peerData) => {
            onViewContextChange &&
                onViewContextChange({
                    ...EMPTY_VIEW_CONTEXT,
                    playerId: playerId,
                    competitionId: competitionId,
                    metricContext: activeMetricContext,
                });
            setViewContext({
                ...EMPTY_VIEW_CONTEXT,
                playerId: playerId,
                competitionId: competitionId,
                metricContext: activeMetricContext,
            });
            setDrilldownPlayerPeerData(peerData);
            setDrilldownOpen(true);
        },
        [activeMetricContext, onViewContextChange]
    );

    const handleMetricContextChange = useCallback(
        (metricContext) => {
            setActiveMetricContext(metricContext);
            onViewContextChange &&
                onViewContextChange({
                    ...EMPTY_VIEW_CONTEXT,
                    playerId: viewContextProp.playerId,
                    competitionId: viewContextProp.competitionId,
                    metricContext: metricContext,
                });
        },
        [onViewContextChange, viewContextProp]
    );

    const onPlayerIndexDrilldownClosed = useCallback(() => {
        // Wait .3s, then set viewContext to null
        setDrilldownOpen(false);
        setTimeout(() => {
            onViewContextChange && onViewContextChange(EMPTY_VIEW_CONTEXT);
            setViewContext(EMPTY_VIEW_CONTEXT);
            setActiveMetricContext({
                stack: null,
                activeMetric: null,
            });
        }, 300);
    }, [onViewContextChange]);

    useEffect(() => {
        if (viewContextProp) {
            setViewContext(viewContextProp);

            if (viewContextProp.playerId) {
                setDrilldownOpen(true);
            }

            if (
                viewContextProp.metricContext &&
                viewContextProp.metricContext.activeMetric !==
                    activeMetricContext.activeMetric
            ) {
                setActiveMetricContext({
                    stack: viewContextProp.metricContext.stack,
                    activeMetric: viewContextProp.metricContext.activeMetric,
                });
            }
        }
    }, [viewContextProp, activeMetricContext.activeMetric]);

    const headerTitle =
        pageClass === "playerseasonperformance"
            ? "Season Performance"
            : pageClass === "playerimpact"
            ? "Player Impact"
            : pageClass === "depthchart"
            ? "Depth Chart"
            : pageClass === "capreport"
            ? "Cap Report"
            : pageClass === "rosteredit"
            ? "Fuck Around Roster"
            : "";

    const [sidePanelWidth, setSidePanelWidth] = useState(null);
    const [mainContentWidth, setMainContentWidth] = useState(null);
    const [mainContentHeight, setMainContentHeight] = useState(null);

    return (
        // Setting a key on the AppPage component forces it to re-mount when
        // the teamId, which is necessary trigger the transition
        // effects.
        <AppPage key={"TeamRosterPages_" + teamId}>
            {["depthchart", "rosteredit"].includes(pageClass) && (
                <>
                    {tileViewMode === "trad_stats" && (
                        <PlayerTradStatsTileLegend
                            zIndex={4}
                            includeTrends={true}
                            includeFreeAgency={true}
                        />
                    )}
                    {tileViewMode === "scouting" && (
                        <ScoutingTileLegend
                            includeFreeAgency={true}
                            zIndex={4}
                        />
                    )}
                    {tileViewMode === "performance" && (
                        <PlayerPerformanceTileLegend
                            zIndex={4}
                            includeTrends={true}
                            includeFreeAgency={true}
                        />
                    )}
                    {tileViewMode === "cap" && (
                        <PlayerCapTileLegend zIndex={4} />
                    )}
                </>
            )}
            {pageClass === "playerimpact" && (
                <TeamPlayerIndexLegend
                    zIndex={4}
                    includeTrends={true}
                    includeFreeAgency={true}
                />
            )}
            <PlayerIndexDrilldownPanel
                playerId={viewContext.playerId}
                competitionId={viewContext.competitionId}
                seasonId={seasonId}
                metricContext={activeMetricContext}
                onMetricContextChange={handleMetricContextChange}
                isOpen={drilldownOpen}
                peersData={drilldownPlayerPeerData}
                onCloseHandler={onPlayerIndexDrilldownClosed}
                targetWidthHandler={setSidePanelWidth}
                showScoutingSummary={true}
                scoutingSummaryMode={"pro"}
            />
            <CompressiblePanel
                compressWidth={sidePanelWidth}
                onWidthChange={setMainContentWidth}
                onHeightChange={setMainContentHeight}
            >
                <OrgHeader
                    fullTitle={
                        (team ? team.location + " " + team.name : "") +
                        " " +
                        headerTitle
                    }
                    mediumTitle={
                        (team ? team.location : "") + " " + headerTitle
                    }
                    shortTitle={headerTitle}
                    onOpen={onMenuOpen}
                    bgColor={team ? team.primary_colour : "#000"}
                    imgUrl={team ? team.alt_logo_url : ""}
                    imgPosition={
                        depth_chart_config
                            ? depth_chart_config.header_object_position
                            : "50% 50%"
                    }
                    parentWidth={mainContentWidth}
                    parentHeight={mainContentHeight}
                />
                <MainContent>
                    <NavigationBar>
                        <TeamSelector
                            selectedTeamId={teamId}
                            onSelectTeam={handleSelectTeamId}
                        />
                        <RosterViewSelector
                            options={pageClassOptions.filter((option) =>
                                canViewContractInformation
                                    ? true
                                    : option.id !== "capreport"
                            )}
                            selectedPageClass={pageClass}
                            onPageClassSelection={handlePageClassSelection}
                        />
                    </NavigationBar>
                    <div
                        className={
                            classes.view_container +
                            (pageClassTransitioning || pageViewContentLoading
                                ? " " + classes.hidden
                                : "")
                        }
                    >
                        {pageClass === "capreport" &&
                            canViewContractInformation && (
                                <CapReportView
                                    teamId={teamId}
                                    onContentLoading={setPageViewContentLoading}
                                />
                            )}
                        {pageClass === "depthchart" && (
                            <DepthChartView
                                teamId={teamId}
                                metricsSeasonId={metricsSeasonId}
                                onPlayerClick={handlePlayerClicked}
                                onContentLoading={setPageViewContentLoading}
                            />
                        )}
                        {pageClass === "rosteredit" && (
                            <RosterEditView
                                teamId={teamId}
                                metricsSeasonId={metricsSeasonId}
                                onPlayerClick={handlePlayerClicked}
                                onContentLoading={setPageViewContentLoading}
                            />
                        )}
                        {pageClass === "playerimpact" && (
                            <TeamPlayerImpactView
                                teamId={teamId}
                                metricsSeasonId={metricsSeasonId}
                                mainContentWidth={mainContentWidth}
                                onPlayerClick={handlePlayerClicked}
                                onContentLoading={setPageViewContentLoading}
                            />
                        )}
                        {pageClass === "playerseasonperformance" && (
                            <TeamPlayerSeasonPerformanceView
                                teamId={teamId}
                                metricsSeasonId={metricsSeasonId}
                                mainContentWidth={mainContentWidth}
                                onPlayerClick={handlePlayerClicked}
                                onContentLoading={setPageViewContentLoading}
                            />
                        )}
                    </div>
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default TeamRosterPages;
