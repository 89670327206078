import { useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchCompetition,
    fetchCompetitionSeasonDatastash,
} from "../store/competitions-actions";
import {
    fetchTeam,
    fetchTeamDeadCapCommitments,
} from "../store/teams-actions";
import {
    fetchNHLTeamReserveListPlayers,
    fetchPlayersContracts,
} from "../store/players-actions";
import MainBox from "../layout/MainBox";
import useAuth from "../hooks/use-auth";
import { useSalaryCap } from "../hooks/use-salary-cap";
import { getPrimaryPosition } from "../utils";
import {
    PlayerFreeAgencyPill,
    PlayerContractClausePill,
    PlayerWaiversExemptionPill,
} from "./playertile/Playertile";
import { ContractClauseDotIndicator } from "./indicators";
import pt_classes from "../components/playertile/Playertile.module.css";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader";
import TimeSeriesChart from "../controls/TimeSeriesChart";

import classes from "./CapReportView.module.css";
import { mp_track } from "../mixpanel";

const cap_report_mp_track = (team, event, properties = null) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
    };

    mp_track(event, properties);
};

const formatCapMetric = (value) => {
    return value?.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
};

const CapProjectionMetric = ({
    label,
    value,
    projection,
    signColors = false,
}) => {
    return (
        <div className={classes.cap_projection_metric}>
            <div className={classes.cap_projection_metric_label}>{label}</div>
            <div
                className={
                    classes.cap_projection_metric_value +
                    (signColors && value > 0
                        ? " " + classes.positive
                        : signColors && value < 0
                        ? " " + classes.negative
                        : "")
                }
            >
                {formatCapMetric(value)}
            </div>
            <div className={classes.cap_projection_metric_projection}>
                <div className={classes.cap_projection_metric_projection_label}>
                    projection
                </div>
                <div
                    className={
                        classes.cap_projection_metric_projection_value +
                        (signColors && value > 0
                            ? " " + classes.positive
                            : signColors && value < 0
                            ? " " + classes.negative
                            : "")
                    }
                >
                    {formatCapMetric(projection)}
                </div>
            </div>
        </div>
    );
};

const TeamLongTermCommitmentChart = ({
    competitionId,
    seasonId,
    teamId,
    metric,
    width = 169,
    height = 120,
    margin = [10, 10, 24, 30],
    isLoadingHandler,
    border = true,
}) => {
    const dispatch = useDispatch();
    const { addLoader } = useMultiResourceLoader(isLoadingHandler);

    const FULL_RANGE_BAND_COLOR = "var(--tw-gray-350)";
    const QUARTER_RANGE_BAND_COLOR = "var(--tw-gray-400)";
    const MEDIAN_COLOR = "var(--tw-slate-450)";
    const METRIC_COLOR = "var(--tw-orange-400)";

    const seasons = useSelector((state) => state.seasons.seasons);

    const competition_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes[competitionId]
    );
    const teamsLongTermCommitments =
        competition_seasondatastashes?.teams_long_term_commitments?.[seasonId];

    // Fetch required data
    useEffect(() => {
        if (
            competitionId &&
            seasonId &&
            teamsLongTermCommitments === undefined
        ) {
            addLoader(`team_long_term_commitments_${metric}`, () =>
                dispatch(
                    fetchCompetitionSeasonDatastash(
                        competitionId,
                        seasonId,
                        "teams_long_term_commitments"
                    )
                )
            );
        }
    }, [
        competitionId,
        seasonId,
        teamsLongTermCommitments,
        metric,
        addLoader,
        dispatch,
    ]);

    const teamDataSeasonMap = useMemo(() => {
        return (
            teamId &&
            teamsLongTermCommitments &&
            teamsLongTermCommitments
                .filter((teamLTdata) => teamLTdata.team_id === teamId)[0]
                ?.seasons.reduce((acc, season) => {
                    acc[season.season_id] =
                        metric === "contracts_count"
                            ? season.players.length
                            : metric === "committed_cap_hit"
                            ? season[metric] / 1000000
                            : season[metric];
                    return acc;
                }, {})
        );
    }, [teamsLongTermCommitments, teamId, metric]);

    const competitionAllTeamsDataSeasonMap = useMemo(() => {
        return (
            teamsLongTermCommitments &&
            teamsLongTermCommitments.reduce((acc, teamLTdata) => {
                teamLTdata.seasons.forEach((season) => {
                    if (!acc[season.season_id]) {
                        acc[season.season_id] = [];
                    }
                    acc[season.season_id].push(
                        metric === "contracts_count"
                            ? season.players.length
                            : metric === "committed_cap_hit"
                            ? season[metric] / 1000000
                            : season[metric]
                    );
                });
                return acc;
            }, {})
        );
    }, [teamsLongTermCommitments, metric]);

    const metricBandsSeasonMap = useMemo(() => {
        return (
            competitionAllTeamsDataSeasonMap &&
            Object.entries(competitionAllTeamsDataSeasonMap).reduce(
                (acc, [seasonId, metricValues]) => {
                    const sortedValues = metricValues.sort((a, b) => a - b);
                    const len = sortedValues.length;

                    const getPercentile = (percentile) => {
                        const index = percentile * (len - 1);
                        const lower = Math.floor(index);
                        const upper = Math.ceil(index);
                        const weight = index - lower;

                        if (upper === lower) return sortedValues[index];
                        return (
                            sortedValues[lower] * (1 - weight) +
                            sortedValues[upper] * weight
                        );
                    };

                    acc[seasonId] = {
                        min: sortedValues[0],
                        max: sortedValues[len - 1],
                        median: getPercentile(0.5),
                        p25: getPercentile(0.25),
                        p75: getPercentile(0.75),
                    };
                    return acc;
                },
                {}
            )
        );
    }, [competitionAllTeamsDataSeasonMap]);

    const earliestSeasonId =
        metricBandsSeasonMap && Math.min(...Object.keys(metricBandsSeasonMap));
    const earliestSeasonYear = seasons[earliestSeasonId]?.start_year;
    const maxSeasonId =
        metricBandsSeasonMap && Math.max(...Object.keys(metricBandsSeasonMap));
    const maxSeasonYear =
        seasons[maxSeasonId] && seasons[maxSeasonId].start_year + 1;
    const minDate = earliestSeasonYear && new Date(earliestSeasonYear, 0, 1);
    const maxDate = maxSeasonYear && new Date(maxSeasonYear, 0, 1);

    // Horizontal ticks are an array of objects. There is one entry per year between earliestSeasonYear and maxSeasonYear.
    // Each entry is an object with the following properties:
    // - x: The date of the tick, which is Jul 1st of the year.
    // - label: The label to display for the tick, which is the player's age that season.
    // - id: an id for the tick, which is also the player's age that season.
    const horizontalTicks = useMemo(() => {
        const horizontalTicks = [];
        if (earliestSeasonYear && maxSeasonYear) {
            for (
                let year = earliestSeasonYear;
                year < maxSeasonYear;
                year += 2
            ) {
                horizontalTicks.push({
                    x: new Date(year, 6, 1),
                    // label is the last two digits of the year.
                    label:
                        year.toString().substring(2, 4) +
                        "-" +
                        (year + 1).toString().substring(2, 4),
                    id: year,
                });
            }
        }
        return horizontalTicks;
    }, [earliestSeasonYear, maxSeasonYear]);

    const timeSeries = useMemo(() => {
        return teamDataSeasonMap && metricBandsSeasonMap
            ? Object.values(
                  Object.entries(metricBandsSeasonMap).reduce(
                      (acc, [seasonId, value]) => {
                          const x_coord = new Date(
                              seasons[seasonId].name.substring(0, 4),
                              6,
                              1
                          );

                          if (teamDataSeasonMap[seasonId]) {
                              acc.team_time_series.data.push({
                                  x: x_coord,
                                  y: teamDataSeasonMap[seasonId],
                              });
                          }
                          acc.competition_median_time_series.data.push({
                              x: x_coord,
                              y: value.median,
                          });

                          return acc;
                      },
                      {
                          team_time_series: {
                              name: "Team",
                              color: METRIC_COLOR,
                              data: [],
                              showPoints: true,
                              line_style: "thick",
                          },
                          competition_median_time_series: {
                              name: "Competition Median",
                              color: MEDIAN_COLOR,
                              data: [],
                              showPoints: false,
                          },
                      }
                  )
              )
            : [];
    }, [teamDataSeasonMap, metricBandsSeasonMap, seasons]);

    const rangeBands = useMemo(() => {
        return metricBandsSeasonMap
            ? Object.values(
                  Object.entries(metricBandsSeasonMap).reduce(
                      (acc, [seasonId, value]) => {
                          const x_coord = new Date(
                              seasons[seasonId].name.substring(0, 4),
                              6,
                              1
                          );

                          acc.full_range_band.data.push({
                              x: x_coord,
                              y_min: value.min,
                              y_max: value.max,
                          });

                          acc.quarter_range_band.data.push({
                              x: x_coord,
                              y_min: value.p25,
                              y_max: value.p75,
                          });

                          return acc;
                      },
                      {
                          full_range_band: {
                              name: "Full Range",
                              color: FULL_RANGE_BAND_COLOR,
                              data: [],
                          },
                          quarter_range_band: {
                              name: "Quarter Range",
                              color: QUARTER_RANGE_BAND_COLOR,
                              data: [],
                          },
                      }
                  )
              )
            : [];
    }, [metricBandsSeasonMap, seasons]);

    return (
        <div className={classes.team_long_term_commitment_chart}>
            <div className={classes.team_long_term_commitment_chart_label}>
                {metric === "contracts_count"
                    ? "Number of Players"
                    : metric === "committed_cap_hit"
                    ? "Committed Cap Hit (M$)"
                    : "Average Player Age"}
            </div>
            <TimeSeriesChart
                width={width}
                height={height}
                margin={margin}
                minY={metric === "average_player_age" ? 25 : null}
                maxY={metric === "average_player_age" ? 40 : null}
                stepsY={metric === "average_player_age" ? 5 : 4}
                series={timeSeries}
                bands={rangeBands}
                horizontalTicks={horizontalTicks}
                includeHorizontalTicksGrid={false}
                border={border}
                minDate={minDate}
                maxDate={maxDate}
            />
        </div>
    );
};

const capReportSections = [
    "Roster",
    "Off Roster",
    "Qualified RFAs",
    "Dead Cap",
    "Buried Cap",
    "Non Roster",
];

const SectionTierHeader = ({ title }) => {
    return <div className={classes.cap_view_section_tier_header}>{title}</div>;
};

const SectionTierFooter = ({ label }) => {
    return <div className={classes.cap_view_section_tier_footer}>{label}</div>;
};

const SectionTimelineHeader = ({ seasonIds, seasons }) => {
    return (
        <div className={classes.cap_view_header_data_columns}>
            <div
                className={
                    classes.cap_view_header_data_column +
                    " " +
                    classes.cap_view_cap_perc_column
                }
            >
                %
            </div>
            {seasonIds.map((seasonId) => {
                return (
                    <div
                        key={seasonId}
                        className={
                            classes.cap_view_header_data_column +
                            " " +
                            classes.cap_view_cap_season_column
                        }
                    >
                        <div>{seasons[seasonId].shorthand}</div>
                    </div>
                );
            })}
        </div>
    );
};

const SectionTimelineFooter = ({ seasonIds, sectionTotals }) => {
    const startSeasonId = seasonIds[0];
    return (
        <div className={classes.cap_view_footer_data_columns}>
            <div
                className={
                    classes.cap_view_header_data_column +
                    " " +
                    classes.cap_view_cap_perc_column
                }
            >
                {sectionTotals[startSeasonId] > 0
                    ? ((sectionTotals[startSeasonId] / 83500000) * 100).toFixed(
                          1
                      )
                    : "-"}
            </div>
            {seasonIds.map((seasonId) => {
                return (
                    <div
                        key={seasonId}
                        className={
                            classes.cap_view_header_data_column +
                            " " +
                            classes.cap_view_cap_season_column
                        }
                    >
                        {sectionTotals[seasonId] > 0
                            ? new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                              }).format(sectionTotals[seasonId])
                            : ""}
                    </div>
                );
            })}
        </div>
    );
};

const PlayerCapReportHeader = ({
    player,
    activeContracts = true,
    flatPills = false,
    inverseColors = false,
}) => {
    return (
        <div className={classes.player_cap_report_header}>
            <div className={classes.player_details_name}>
                {player.known_name}{" "}
            </div>
            {activeContracts && (
                <div className={classes.player_info}>
                    <div className={classes.player_info_text}>
                        {player.position} {player.age}
                        {"yo"}
                    </div>
                    {Object.values(player.seasons_ctx).some(
                        (season) => season.is_elc
                    ) && (
                        <PlayerContractClausePill
                            clause={"elc"}
                            flat={flatPills}
                            inverseColors={inverseColors}
                        />
                    )}
                    {Object.values(player.seasons_ctx).some(
                        (season) => season.perf_bonus > 0
                    ) && (
                        <PlayerContractClausePill
                            clause={"perf_bonus"}
                            flat={flatPills}
                            inverseColors={inverseColors}
                        />
                    )}
                    {!player.requires_waivers && (
                        <PlayerWaiversExemptionPill flat={flatPills} />
                    )}
                    {Object.values(player.seasons_ctx).some(
                        (season) => season.has_m_ntc
                    ) && (
                        <PlayerContractClausePill
                            clause={"m_ntc"}
                            flat={flatPills}
                            inverseColors={inverseColors}
                        />
                    )}
                    {Object.values(player.seasons_ctx).some(
                        (season) => season.has_ntc
                    ) && (
                        <PlayerContractClausePill
                            clause={"ntc"}
                            flat={flatPills}
                            inverseColors={inverseColors}
                        />
                    )}
                    {Object.values(player.seasons_ctx).some(
                        (season) => season.has_nmc
                    ) && (
                        <PlayerContractClausePill
                            clause={"nmc"}
                            flat={flatPills}
                            inverseColors={inverseColors}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

const PlayerCapTimeline = ({
    player,
    seasonIds,
    activeContracts = true,
    showCapHitPercentage = false,
    inverseColors = false,
}) => {
    const startSeasonId = seasonIds[0];
    return (
        <div className={classes.player_cap_timeline}>
            <div
                className={
                    classes.player_cap_timeline_column +
                    " " +
                    classes.cap_view_cap_perc_column
                }
            >
                {showCapHitPercentage &&
                player.seasons_ctx[startSeasonId] &&
                player.seasons_ctx[startSeasonId].cap_hit > 0
                    ? (
                          (player.seasons_ctx[startSeasonId].cap_hit /
                              83500000) *
                          100
                      ).toFixed(1)
                    : "-"}
            </div>
            {seasonIds.map((seasonId) => {
                const s_ctx = player.seasons_ctx[seasonId];
                return (
                    <div
                        key={seasonId}
                        className={
                            classes.player_cap_timeline_column +
                            " " +
                            (seasonId <=
                            Math.max(
                                ...Object.keys(player.seasons_ctx).map((key) =>
                                    parseInt(key, 10)
                                )
                            )
                                ? classes.cap_view_cap_season_column
                                : classes.cap_view_free_agency_column)
                        }
                    >
                        {s_ctx && s_ctx.cap_hit > 0 ? (
                            <>
                                <div className={classes.cap_hit_number}>
                                    {new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    }).format(s_ctx.cap_hit)}
                                </div>
                                <div className={classes.clauses_indicators}>
                                    {s_ctx.has_m_ntc && (
                                        <ContractClauseDotIndicator
                                            clause={"m_ntc"}
                                            inverseColors={inverseColors}
                                        />
                                    )}
                                    {s_ctx.has_ntc && (
                                        <ContractClauseDotIndicator
                                            clause={"ntc"}
                                            inverseColors={inverseColors}
                                        />
                                    )}
                                    {s_ctx.has_nmc && (
                                        <ContractClauseDotIndicator
                                            clause={"nmc"}
                                            inverseColors={inverseColors}
                                        />
                                    )}
                                </div>
                            </>
                        ) : seasonId <
                          Math.max(
                              ...Object.keys(player.seasons_ctx).map((key) =>
                                  parseInt(key, 10)
                              )
                          ) ? (
                            "-"
                        ) : activeContracts &&
                          seasonId ===
                              Math.max(
                                  ...Object.keys(player.seasons_ctx).map(
                                      (key) => parseInt(key, 10)
                                  )
                              ) +
                                  1 ? (
                            <div className={classes.free_agency_pill_container}>
                                <PlayerFreeAgencyPill
                                    freeAgencyClass={
                                        player.is_projected_ufa
                                            ? pt_classes.projected_ufa
                                            : player.is_projected_rfa
                                            ? pt_classes.projected_rfa
                                            : null
                                    }
                                    isArbitrationEligible={
                                        player.is_projected_arbitration_eligible
                                    }
                                />
                            </div>
                        ) : (
                            " "
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const PlayersCapView = ({
    players,
    startSeasonId,
    endSeasonId,
    activeContracts = true,
    showCapHitPercentage = false,
    showSectionTotals = false,
    tieringCritera,
}) => {
    const seasonIds = useMemo(() => {
        // +2 to include the start and end seasons, and all seasons in between,
        // and have an extra season for the free agency marker.
        return Array.from(
            { length: endSeasonId - startSeasonId + 2 },
            (_, i) => {
                return startSeasonId + i;
            }
        );
    }, [startSeasonId, endSeasonId]);
    const seasons = useSelector((state) => state.seasons.seasons);

    const flatPills = true;

    const sectionTiers =
        tieringCritera === "position"
            ? [
                  {
                      label: "Forwards",
                      players: players.filter(
                          (p) => getPrimaryPosition(p.position) === "F"
                      ),
                  },
                  {
                      label: "Defensemen",
                      players: players.filter(
                          (p) => getPrimaryPosition(p.position) === "D"
                      ),
                  },
                  {
                      label: "Goalies",
                      players: players.filter(
                          (p) => getPrimaryPosition(p.position) === "G"
                      ),
                  },
              ]
            : tieringCritera === "injury_reserve_class"
            ? [
                  {
                      label: "Injured Reserve",
                      players: players.filter(
                          (p) => p.injured_reserve_class === "IR"
                      ),
                  },
                  {
                      label: "Long\u00A0Term Injured\u00A0Reserve",
                      tall_header_row: true,
                      players: players.filter(
                          (p) => p.injured_reserve_class === "LTIR"
                      ),
                  },
                  {
                      label: "Other",
                      players: players.filter(
                          (p) =>
                              !["IR", "LTIR"].includes(p.injured_reserve_class)
                      ),
                  },
              ]
            : tieringCritera === "dead_cap_type"
            ? [
                  {
                      label: "Retained Salary",
                      players: players
                          ? players.filter(
                                (p) => p?.dead_cap_class === "retained_salary"
                            )
                          : [],
                  },
                  {
                      label: "Contract Buyouts",
                      players: players
                          ? players.filter(
                                (p) => p?.dead_cap_class === "contract_buyout"
                            )
                          : [],
                  },
                  {
                      label: "Other",
                      players: players
                          ? players.filter((p) => p?.dead_cap_class === "other")
                          : [],
                  },
              ]
            : [{ label: "", players: players }];

    const filteredSectionTiers = sectionTiers.filter(
        (sectionTier) => sectionTier.players.length > 0
    );

    return (
        <div className={classes.cap_view}>
            <div className={classes.cap_view_column_players_details}>
                {filteredSectionTiers.map((sectionTier) => {
                    return (
                        <div
                            key={sectionTier.label || "single_section"}
                            className={
                                classes.cap_view_section_tier_column +
                                " " +
                                classes.details_section_tier_column
                            }
                        >
                            <div
                                className={
                                    classes.cap_view_header_row +
                                    (sectionTier.tall_header_row
                                        ? " " + classes.tall
                                        : "")
                                }
                            >
                                <SectionTierHeader title={sectionTier.label} />
                            </div>
                            <div className={classes.cap_view_section_content}>
                                {sectionTier.players
                                    .sort(
                                        (a, b) =>
                                            (b.seasons_ctx[startSeasonId]
                                                ?.cap_hit || 0) -
                                            (a.seasons_ctx[startSeasonId]
                                                ?.cap_hit || 0)
                                    )
                                    .map((player, idx) => {
                                        return (
                                            <div
                                                key={player.id}
                                                className={
                                                    classes.cap_view_player_row +
                                                    (activeContracts
                                                        ? ""
                                                        : " " +
                                                          classes.inactive) +
                                                    (idx % 2 === 0
                                                        ? " " + classes.alt_row
                                                        : "")
                                                }
                                            >
                                                <PlayerCapReportHeader
                                                    player={player}
                                                    activeContracts={
                                                        activeContracts
                                                    }
                                                    flatPills={flatPills}
                                                    inverseColors={
                                                        idx % 2 === 0
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                            {showSectionTotals &&
                                sectionTier.players.length > 1 && (
                                    <div
                                        className={classes.cap_view_footer_row}
                                    >
                                        <SectionTierFooter label="Totals" />
                                    </div>
                                )}
                        </div>
                    );
                })}
            </div>
            <div className={classes.cap_view_column_players_timeline}>
                {filteredSectionTiers.map((sectionTier) => {
                    const sectionTotals = showSectionTotals
                        ? seasonIds.reduce((acc, seasonId) => {
                              acc[seasonId] = sectionTier.players.reduce(
                                  (total, player) => {
                                      return (
                                          total +
                                          (parseFloat(
                                              player.seasons_ctx[seasonId]
                                                  ?.cap_hit
                                          ) || 0)
                                      );
                                  },
                                  0
                              );
                              return acc;
                          }, {})
                        : {};
                    return (
                        <div
                            key={sectionTier.label || "single_section"}
                            className={
                                classes.cap_view_section_tier_column +
                                " " +
                                classes.timeline_section_tier_column
                            }
                        >
                            <div
                                className={
                                    classes.cap_view_header_row +
                                    (sectionTier.tall_header_row
                                        ? " " + classes.tall
                                        : "")
                                }
                            >
                                <SectionTimelineHeader
                                    seasonIds={seasonIds}
                                    seasons={seasons}
                                />
                            </div>
                            {sectionTier.players
                                .sort(
                                    (a, b) =>
                                        (b.seasons_ctx[startSeasonId]
                                            ?.cap_hit || 0) -
                                        (a.seasons_ctx[startSeasonId]
                                            ?.cap_hit || 0)
                                )
                                .map((player, idx) => {
                                    return (
                                        <div
                                            key={player.id}
                                            className={
                                                classes.cap_view_player_row +
                                                (activeContracts
                                                    ? ""
                                                    : " " + classes.inactive) +
                                                (idx % 2 === 0
                                                    ? " " + classes.alt_row
                                                    : "")
                                            }
                                        >
                                            <PlayerCapTimeline
                                                player={player}
                                                seasonIds={seasonIds}
                                                activeContracts={
                                                    activeContracts
                                                }
                                                showCapHitPercentage={
                                                    showCapHitPercentage
                                                }
                                                inverseColors={idx % 2 === 0}
                                            />
                                        </div>
                                    );
                                })}
                            {showSectionTotals &&
                                sectionTier.players.length > 1 && (
                                    <div
                                        className={classes.cap_view_footer_row}
                                    >
                                        <SectionTimelineFooter
                                            seasonIds={seasonIds}
                                            sectionTotals={sectionTotals}
                                        />
                                    </div>
                                )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const CapReportView = ({ teamId, onContentLoading }) => {
    const { checkPermission } = useAuth();
    const { CURRENT_SALARY_CAP, getPlayerCapBase } = useSalaryCap();

    const canViewContractInformation = checkPermission(
        "core.can_view_contract_information"
    );

    const dispatch = useDispatch();
    const error = useSelector((state) => state.ui.notification);

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.contractViewsSeasonId
    );
    const players = useSelector((state) => state.players.players);

    const teams = useSelector((state) => state.teams.teams);

    const team = teams && teams[teamId];

    const competitionId = 1;
    const competition = useSelector(
        (state) => state.competitions.competitions[competitionId]
    );
    const competition_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes[competitionId]
    );

    const teamsLongTermCommitments =
        competition_seasondatastashes?.teams_long_term_commitments?.[
            currentSeasonId
        ];

    const baselineCapHit =
        teamsLongTermCommitments &&
        teamsLongTermCommitments
            .filter((teamLTdata) => teamLTdata.team_id === teamId)[0]
            .seasons.filter((season) => season.season_id === currentSeasonId)[0]
            .baseline_cap_hit;

    const teamIdRef = useRef(null);

    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            cap_report_mp_track(team, "View NHL Cap Report");
            teamIdRef.current = team.id;
        }
    }, [team]);

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const all_requiredPlayerIds = useMemo(() => {
        // Ensure teams_reserveListPlayerIds is an array, even when null
        const safeTeamsReserveListPlayerIds = teams_reserveListPlayerIds || [];
        return safeTeamsReserveListPlayerIds;
    }, [teams_reserveListPlayerIds]);

    const playerContracts = useSelector((state) => state.players.contracts);

    const reserveListOnContractCount = useMemo(() => {
        return all_requiredPlayerIds.reduce((acc, playerId) => {
            return playerContracts[playerId] ? acc + 1 : acc;
        }, 0);
    }, [all_requiredPlayerIds, playerContracts]);

    const missingPlayerIds = useMemo(() => {
        // Filter the all_requiredPlayerIds array to only include players that
        // are missing from the players store
        return all_requiredPlayerIds.filter((id) => !players[id]);
    }, [all_requiredPlayerIds, players]);

    const getPlayerSeasonAdjustedCapHit = (playerSeason) => {
        const retained =
            playerSeason.retained_slices?.reduce(
                (acc, slice) => acc + parseFloat(slice.retained_salary),
                0
            ) || 0;

        return playerSeason.cap_hit - retained;
    };

    const tieredPlayers = useMemo(() => {
        if (teams_reserveListPlayerIds) {
            return teams_reserveListPlayerIds.reduce(
                (acc, playerId) => {
                    if (players[playerId]) {
                        const player = {
                            ...players[playerId],
                            contracts: playerContracts[playerId] || [],
                            // Get all contract seasons cap_hit values, mapped
                            // to their season_id.
                            // If a season id is seen multiple times in
                            // different contracts, ignore later values, as
                            // contracts are sorted by reverse signature date,
                            // so first contract is the most recent.
                            // Simlarly, earlier contracts cannot populate season ids
                            // greater than the minimum season id that has been seen.
                            seasons_ctx: playerContracts[playerId]
                                ? playerContracts[playerId].reduce(
                                      (acc, contract) => {
                                          // Determine the minimum season id that
                                          // has been seen.
                                          const minSeasonId = Math.min(
                                              ...Object.keys(acc).map((key) =>
                                                  parseInt(key, 10)
                                              )
                                          );

                                          contract.seasons.forEach((season) => {
                                              if (
                                                  season.season_id >=
                                                      currentSeasonId &&
                                                  season.season_id <
                                                      minSeasonId &&
                                                  !acc[season.season_id]
                                              ) {
                                                  acc[season.season_id] = {
                                                      cap_hit:
                                                          getPlayerSeasonAdjustedCapHit(
                                                              season
                                                          ),
                                                      perf_bonus:
                                                          season.max_performance_bonus,
                                                      has_m_ntc:
                                                          season.has_m_ntc,
                                                      has_ntc: season.has_ntc,
                                                      has_nmc: season.has_nmc,
                                                      is_elc:
                                                          contract.type ===
                                                          "entry_level",
                                                  };
                                              }
                                          });
                                          return acc;
                                      },
                                      {}
                                  )
                                : {},
                        };
                        if (player.is_qualified_rfa) {
                            acc.qualifiedRFAs.push(player);
                        } else if (player.is_on_active_roster) {
                            acc.roster.push(player);
                        } else if (
                            player.is_signed &&
                            !player.is_cleared_non_roster
                        ) {
                            acc.offRoster.push(player);
                        } else if (
                            player.is_signed &&
                            !player.has_two_way_contract &&
                            // Players that have a non zero cap base are to be marked as
                            // buried cap players.
                            getPlayerCapBase(
                                player,
                                Object.values(player.seasons_ctx)[0]?.cap_hit
                            ) > 0
                        ) {
                            acc.buriedCap.push({
                                ...player,
                                seasons_ctx: Object.entries(
                                    player.seasons_ctx
                                ).reduce((acc, [seasonId, season]) => {
                                    acc[seasonId] = {
                                        ...season,
                                        cap_hit: getPlayerCapBase(
                                            player,
                                            getPlayerSeasonAdjustedCapHit(
                                                season
                                            )
                                        ),
                                    };
                                    return acc;
                                }, {}),
                            });
                        } else if (player.is_signed) {
                            acc.nonRoster.push(player);
                        }
                    }
                    return acc;
                },
                {
                    roster: [],
                    offRoster: [],
                    qualifiedRFAs: [],
                    buriedCap: [],
                    nonRoster: [],
                }
            );
        }
        return {
            roster: [],
            offRoster: [],
            qualifiedRFAs: [],
            buriedCap: [],
            nonRoster: [],
        };
    }, [
        teams_reserveListPlayerIds,
        players,
        playerContracts,
        currentSeasonId,
        getPlayerCapBase,
    ]);

    const maxFutureSeasonId = useMemo(() => {
        return (
            // Get the maximum season_id from all tiered players contracts
            tieredPlayers &&
            Object.values(tieredPlayers).reduce((acc, players) => {
                return players.reduce((acc, player) => {
                    return Object.keys(player.seasons_ctx).reduce(
                        (acc, seasonId) => {
                            return Math.max(acc, seasonId);
                        },
                        acc
                    );
                }, acc);
            }, currentSeasonId)
        );
    }, [tieredPlayers, currentSeasonId]);

    const storeTeamDeadCapCommitments = useSelector(
        (state) => state.teams.deadCapCommitments[teamId]
    );
    const teamDeadCapCommitments = useMemo(() => {
        return (
            storeTeamDeadCapCommitments &&
            storeTeamDeadCapCommitments.reduce((acc, commitment) => {
                acc[commitment.season_id] = commitment;
                return acc;
            }, {})
        );
    }, [storeTeamDeadCapCommitments]);

    const tieredDeadCapCommitments = useMemo(() => {
        const bonusSeasonCtx =
            teamDeadCapCommitments &&
            Object.entries(teamDeadCapCommitments).reduce(
                (acc, [seasonId, value]) => {
                    if (value && value.bonus_overages > 0) {
                        acc[seasonId] = {
                            cap_hit: value.bonus_overages,
                        };
                    }
                    return acc;
                },
                {}
            );

        const bonusCtx =
            bonusSeasonCtx && Object.values(bonusSeasonCtx).length > 0
                ? {
                      id: "bonus_overages",
                      known_name: "Bonus Overages",
                      dead_cap_class: "other",
                      seasons_ctx: bonusSeasonCtx,
                  }
                : {};

        const retainedSalaryPlayerCtx =
            teamDeadCapCommitments &&
            Object.entries(teamDeadCapCommitments).reduce(
                (acc, [seasonId, value]) => {
                    if (value) {
                        value.retained_contracts.forEach((contract) => {
                            if (acc[contract.player_id] === undefined) {
                                acc[contract.player_id] = {
                                    id: contract.player_id,
                                    known_name: contract.player_known_name,
                                    dead_cap_class: "retained_salary",
                                    seasons_ctx: {},
                                };
                            }
                            acc[contract.player_id].seasons_ctx[seasonId] = {
                                cap_hit: contract.cap_hit,
                            };
                        });
                    }

                    return acc;
                },
                {}
            );

        const contractBuyoutsPlayerCtx =
            teamDeadCapCommitments &&
            Object.entries(teamDeadCapCommitments).reduce(
                (acc, [seasonId, value]) => {
                    if (value) {
                        value.buyout_contracts.forEach((contract) => {
                            if (acc[contract.player_id] === undefined) {
                                acc[contract.player_id] = {
                                    id: contract.player_id,
                                    known_name: contract.player_known_name,
                                    dead_cap_class: "contract_buyout",
                                    seasons_ctx: {},
                                };
                            }
                            acc[contract.player_id].seasons_ctx[seasonId] = {
                                cap_hit: contract.cap_hit,
                            };
                        });
                    }

                    return acc;
                },
                {}
            );

        // Concate all the dead cap commitment players into a single array
        const deadCapCommitmentPlayers = bonusCtx &&
            retainedSalaryPlayerCtx &&
            contractBuyoutsPlayerCtx && [
                bonusCtx,
                ...Object.values(retainedSalaryPlayerCtx),
                ...Object.values(contractBuyoutsPlayerCtx),
            ];

        return deadCapCommitmentPlayers;
    }, [teamDeadCapCommitments]);

    const playersMissing = useMemo(
        () => missingPlayerIds.length > 0,
        [missingPlayerIds]
    );

    // Fetch required data
    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            onContentLoading && onContentLoading(true);
        }

        if (!competition) {
            dispatch(fetchCompetition(competitionId));
            onContentLoading && onContentLoading(true);
        }

        if (!teams_reserveListPlayerIds || playersMissing) {
            dispatch(fetchNHLTeamReserveListPlayers(teamId, true));
        }

        if (!teamDeadCapCommitments) {
            dispatch(fetchTeamDeadCapCommitments(teamId));
            onContentLoading && onContentLoading(true);
        }

        // The number 10 is arbitrary, but it's a reasonable number of players
        // to check for.
        if (reserveListOnContractCount < 10) {
            dispatch(fetchPlayersContracts(null, currentSeasonId, teamId));
            onContentLoading && onContentLoading(true);
        }

        // If the competition's season datastash is missing, fetch it.
        // If it's an empty array, then the season datastash was fetched,
        // so consider it is there.
        if (
            competitionId &&
            currentSeasonId &&
            teamsLongTermCommitments === undefined
        ) {
            dispatch(
                fetchCompetitionSeasonDatastash(
                    competitionId,
                    currentSeasonId,
                    "teams_long_term_commitments"
                )
            );
            onContentLoading && onContentLoading(true);
        }
    }, [
        teamId,
        teams,
        competition,
        playersMissing,
        teams_reserveListPlayerIds,
        reserveListOnContractCount,
        currentSeasonId,
        competitionId,
        teamDeadCapCommitments,
        teamsLongTermCommitments,
        onContentLoading,
        dispatch,
    ]);

    useEffect(() => {
        if (
            team &&
            competition &&
            !playersMissing &&
            teamDeadCapCommitments &&
            reserveListOnContractCount >= 10 &&
            teamsLongTermCommitments !== undefined
        ) {
            onContentLoading && onContentLoading(false);
        }
    }, [
        team,
        competition,
        playersMissing,
        reserveListOnContractCount,
        teamDeadCapCommitments,
        teamsLongTermCommitments,
        onContentLoading,
        dispatch,
    ]);

    return (
        <>
            {canViewContractInformation && team && !playersMissing ? (
                <div className={classes.cap_report_container}>
                    <div className={classes.cap_report_header}>
                        <div className={classes.cap_report_projection_metrics}>
                            <CapProjectionMetric
                                label="Cap Hit"
                                value={baselineCapHit}
                                projection={baselineCapHit}
                            />
                            <CapProjectionMetric
                                label="Cap Space"
                                value={CURRENT_SALARY_CAP - baselineCapHit}
                                projection={CURRENT_SALARY_CAP - baselineCapHit}
                                signColors={true}
                            />
                        </div>
                        <div className={classes.cap_report_ltc_charts}>
                            <TeamLongTermCommitmentChart
                                competitionId={competitionId}
                                seasonId={currentSeasonId}
                                teamId={teamId}
                                metric="committed_cap_hit"
                            />
                            <TeamLongTermCommitmentChart
                                competitionId={competitionId}
                                seasonId={currentSeasonId}
                                teamId={teamId}
                                metric="contracts_count"
                            />
                            <TeamLongTermCommitmentChart
                                competitionId={competitionId}
                                seasonId={currentSeasonId}
                                teamId={teamId}
                                metric="average_player_age"
                            />
                        </div>
                    </div>
                    <div className={classes.cap_report_content}>
                        {capReportSections.map((section) => {
                            return (
                                <MainBox
                                    key={section}
                                    bgColor={"white"}
                                    clearOnNarrow={true}
                                >
                                    <div className={classes.cap_report_section}>
                                        <div
                                            className={
                                                classes.cap_report_section_header
                                            }
                                        >
                                            <div>{section}</div>
                                        </div>
                                        <div
                                            className={
                                                classes.cap_report_section_content
                                            }
                                        >
                                            <PlayersCapView
                                                players={
                                                    section === "Roster"
                                                        ? tieredPlayers.roster
                                                        : section ===
                                                          "Off Roster"
                                                        ? tieredPlayers.offRoster
                                                        : section ===
                                                          "Qualified RFAs"
                                                        ? tieredPlayers.qualifiedRFAs
                                                        : section === "Dead Cap"
                                                        ? tieredDeadCapCommitments
                                                        : section ===
                                                          "Buried Cap"
                                                        ? tieredPlayers.buriedCap
                                                        : tieredPlayers.nonRoster
                                                }
                                                startSeasonId={currentSeasonId}
                                                endSeasonId={maxFutureSeasonId}
                                                tieringCritera={
                                                    [
                                                        "Roster",
                                                        "Non Roster",
                                                    ].includes(section)
                                                        ? "position"
                                                        : [
                                                              "Off Roster",
                                                          ].includes(section)
                                                        ? "injury_reserve_class"
                                                        : ["Dead Cap"].includes(
                                                              section
                                                          )
                                                        ? "dead_cap_type"
                                                        : null
                                                }
                                                activeContracts={[
                                                    "Roster",
                                                    "Off Roster",
                                                    "Non Roster",
                                                    "Buried Cap",
                                                ].includes(section)}
                                                showCapHitPercentage={[
                                                    "Roster",
                                                    "Off Roster",
                                                    "Dead Cap",
                                                    "Buried Cap",
                                                ].includes(section)}
                                                showSectionTotals={[
                                                    "Roster",
                                                    "Dead Cap",
                                                    "Off Roster",
                                                    "Buried Cap",
                                                ].includes(section)}
                                            />
                                        </div>
                                    </div>
                                </MainBox>
                            );
                        })}
                    </div>
                </div>
            ) : !canViewContractInformation ? (
                <div>
                    <div>You do not have permission to view this page.</div>
                </div>
            ) : error ? (
                <section>
                    <div>{error.title}</div>
                    <div>{error.message}</div>
                </section>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default CapReportView;
